import React from 'react';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  sectionMargins,
  brandColours,
  fontWeights,
  fontSize,
  standardTransition,
  visuallyHidden,
} from '../styles';
import { Container, Svg } from './ui';
import shareIcon from '../images/share-icon.inline.svg';
import facebookIcon from '../images/facebook-icon.inline.svg';
import twitterIcon from '../images/twitter-icon.inline.svg';
import linkedinIcon from '../images/linkedin-icon.inline.svg';
import mailIcon from '../images/mail-icon.inline.svg';

const StyledSocialShare = styled.div`
  ${sectionMargins(undefined, '80px')};
  text-align: center;
`;

const StyledInner = styled.div`
  padding: 30px;
  border-top: 1px solid ${brandColours.primary};
  border-bottom: 1px solid ${brandColours.primary};

  ${minBreakpointQuery.tiny`
    padding: 40px;
  `}

  ${minBreakpointQuery.small`
    display: flex;
    justify-content: space-around;
    align-items: center;
  `}

  ${minBreakpointQuery.medium`
    padding: 50px;
  `}

  ${minBreakpointQuery.large`
    padding: 60px;
  `}

  ${minBreakpointQuery.xxlarge`
    padding: 65px;
  `}
`;

const StyledHeading = styled.p`
  color: ${brandColours.primary};
  font-weight: ${fontWeights.bold};
  ${fontSize(18)};
  white-space: nowrap;

  ${maxBreakpointQuery.small`
    margin-bottom: 20px;
  `}

  ${minBreakpointQuery.small`
    margin-right: 20px;
  `}
  
  ${minBreakpointQuery.large`
    ${fontSize(20)};
  `}
`;

const StyledButton = styled.button`
  color: ${brandColours.primary};
  font-weight: ${fontWeights.semibold};
  background: none;
  border: none;

  ${minBreakpointQuery.small`
    ${fontSize(18)};
  `}

  ${minBreakpointQuery.large`
    ${fontSize(20)};
  `}
  
  span {
    display: inline-block;
    margin-right: 10px;
  }
`;

const StyledList = styled.ul`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
`;

const StyledItem = styled.li`
  margin-left: 20px;

  &:first-child {
    margin-left: 0;
  }
`;

const StyledLink = styled.a`
  display: block;
`;

const StyledIcon = styled(Svg)`
  vertical-align: middle;
  height: 24px;
  width: 24px;
  fill: ${brandColours.primary};
  transition: ${standardTransition('fill')};

  &:hover {
    fill: ${brandColours.quaternary};
  }
`;

const StyledText = styled.span`
  ${visuallyHidden()};
`;

const SocialShare = ({
  heading,
  pageTitle,
  pageData: { origin, pathname },
  ...props
}) => {
  const pageUrl = origin + pathname;

  const links = [
    {
      url: `https://www.facebook.com/sharer/sharer.php?u=${pageUrl}`,
      icon: facebookIcon,
      text: 'Share on Facebook',
    },
    {
      url: `https://twitter.com/intent/tweet?url=${pageUrl}&text=${pageTitle}`,
      icon: twitterIcon,
      text: 'Share on Twitter',
    },
    {
      url: `http://www.linkedin.com/shareArticle?mini=true&url=${pageUrl}&title=${pageTitle}`,
      icon: linkedinIcon,
      text: 'Share on LinkedIn',
    },
    {
      url: `mailto:?subject=${pageTitle} (${pageUrl})`,
      icon: mailIcon,
      text: 'Share via Email',
    },
  ];

  return (
    <StyledSocialShare {...props}>
      <Container narrow={true}>
        <StyledInner>
          <StyledHeading>{heading}</StyledHeading>
          {typeof window !== 'undefined' && navigator && navigator.share ? (
            <StyledButton
              onClick={() =>
                navigator.share({
                  title: pageTitle,
                  text: pageTitle,
                  url: pageUrl,
                })
              }
            >
              <span>Share</span>
              <StyledIcon image={shareIcon} />
            </StyledButton>
          ) : (
            <StyledList>
              {links.map(({ url, icon, text }, id) => (
                <StyledItem key={id}>
                  <StyledLink href={url} rel="noopener noreferrer">
                    <StyledIcon image={icon} />
                    <StyledText>{text}</StyledText>
                  </StyledLink>
                </StyledItem>
              ))}
            </StyledList>
          )}
        </StyledInner>
      </Container>
    </StyledSocialShare>
  );
};

export default SocialShare;

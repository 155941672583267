import React from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import ModularBlocks from '../components/ModularBlocks';
import SocialShare from '../components/SocialShare';
import NewsListing from '../components/NewsListing';

const NewsSingleTemplate = ({
  data: {
    datoCmsNews: {
      seoMetaTags,
      title,
      meta: { firstPublishedAt },
      featuredImage,
      modularBlocks,
    },
    allDatoCmsNews: { nodes },
  },
  location: pageData,
}) => (
  <Layout isNewsSingle={true}>
    <HelmetDatoCms seo={seoMetaTags} />
    <main>
      <Banner
        heading={title}
        text={firstPublishedAt}
        media={featuredImage}
        isNewsSingle={true}
      />
      <ModularBlocks items={modularBlocks} isNewsSingle={true} />
      <SocialShare
        heading="Share This Post:"
        pageTitle={title}
        pageData={pageData}
      />
      <NewsListing heading="In Other Recent News" items={nodes} />
    </main>
  </Layout>
);

export const NewsSingleTemplateQuery = graphql`
  query NewsSingleTemplateQuery($id: String!) {
    datoCmsNews(id: { eq: $id }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      meta {
        firstPublishedAt(formatString: "DD MMM YYYY")
      }
      featuredImage {
        gatsbyImageData(width: 2000, height: 800)
        alt
        isImage
      }
      modularBlocks {
        ...ContainedImageModularBlockFragment
        ...ContentModularBlockFragment
        ...ExternalVideoModularBlockFragment
        ...InternalVideoModularBlockFragment
      }
    }
    allDatoCmsNews(
      filter: { id: { ne: $id } }
      sort: { fields: meta___firstPublishedAt, order: DESC }
      limit: 2
    ) {
      nodes {
        ...NewsCardFragment
      }
    }
  }
`;

export default NewsSingleTemplate;
